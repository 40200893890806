import type { ActionFunctionArgs } from '@remix-run/node';
import type { LoaderFunctionArgs } from '@remix-run/node';
import { Form, json } from '@remix-run/react';

export const loader = async ({ request }: LoaderFunctionArgs) => {
  if (request.url.includes('error='))
    throw new Error('This is a test error from the loader');
  return null;
};

export const action = async ({ request }: ActionFunctionArgs) => {
  const data = await request.formData();
  if (data.get('error')) throw new Error('This is a test error from an action');

  return json({ ok: true });
};

export default function RouteComponent() {
  return (
    <div className='text-white'>
      <button
        type='button'
        onClick={() => fetch('/error-testing-sentry?error=loader')}
      >
        Throw error from loader
      </button>

      <Form method='post'>
        <input type='hidden' name='error' value='truthy' />
        <button type='submit'>Throw error from action</button>
      </Form>
    </div>
  );
}
